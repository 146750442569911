<template>
  <VerifiedComplaintBox
    vcContinueBtnText="Save & Continue"
    @vcBoxContinue="next"
    @vcBoxBack="goBack"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
  >
    <div class="custom-title pt-8 pb-4">Let's Finalize</div>

    <div class="custom-subtitle mb-4 mt-2">Preview</div>
    <div class="mb-12" v-if="!isInStandaloneForms">
      Please take a moment to review carefully. Should you need to make changes,
      jump to the section by clicking on the navigation menu in the sidebar.
    </div>
    <div
      style="
        height: 538px;
        border: 1px solid #8ba4b4;
        border-radius: 20px;
        position: relative;
        z-index: 1;
      "
      class="py-4"
    >
      <div class="magnify-icon">
        <v-icon large @click="openPDF"> mdi-magnify </v-icon>
      </div>
      <PdfPreviewProgressBar :isPdfLoading="isLoading" />
      <vue-scroll :ops="ops">
        <PdfViewer v-if="pdf" :src="pdf" />
      </vue-scroll>
    </div>

    <div class="custom-subtitle mb-4 mt-12">Attorney Signature Block</div>
    <div class="pb-6">
      Based on the information you have provided, your signature block will
      appear as follows. You may edit the information as you see fit.
    </div>
    <div
      style="height: 132px; padding: 20px"
      class="signature-pad mt-6 d-flex align-center justify-center"
    >
      <EditableField
        v-model="signatureText"
        inputStyles="width: 490px;"
        valueStyles="width: 490px;"
      />
    </div>

    <div v-if="isEfile || isInStandaloneForms">
      <div class="custom-subtitle mb-4 mt-12">Attorney Signature</div>
      <div class="pb-6">
        <div v-if="isInStandaloneForms">
          All pleadings must be properly executed. If you intend to e-file,
          provide an electronic signature below.
          <span class="font-weight-medium">
            If you intend to file in person or mail in, print the document and
            provide your original signature. <u>Do not</u> sign below.
          </span>
        </div>
        <div v-else>
          All pleadings must be properly executed. Provide an electronic
          signature below. (Keep in mind, if you intend to mail in or file in
          person, please print the document and provide your original
          signature.)
        </div>
      </div>
      <VueSignaturePad
        ref="signaturePad"
        height="155px"
        class="signature-pad mt-6"
      />
      <v-btn
        style="margin-top: -40px"
        text
        class="btn--plain float-right pr-2"
        color="#8BA4B4"
        @click="clear"
      >
        Clear
      </v-btn>
    </div>

    <v-dialog v-model="successDialog" persistent value="''" max-width="600">
      <SuccessDialog btnText="Go To Pleadings" @submit="completeCase">
        <template v-slot:success-text>
          <span>
            <!-- eslint-disable-next-line -->
            You have successfully drafted the
            <span
              v-if="currentCase.clrsSubmission || currentCase.clrsExemption"
              class="font-weight-medium"
            >
              <span v-if="!isInStandaloneForms"
                >Verified Complaint, Tenant Summons and </span
              >Certification of Lease and Registration Statement.
            </span>
            <span v-else class="font-weight-medium">
              Verified Complaint and Tenant Summons.
            </span>
            Access your Pleadings on the Matter Management screen to download
            your documents.
          </span>
        </template>
      </SuccessDialog>
    </v-dialog>
  </VerifiedComplaintBox>
</template>

<script>
import EditableField from "@/components/EditableField.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import {
  getAttorneySignatureText,
  auth0OptionsHelper,
  openPDFInNewTab,
} from "@/utils";
import { mapGetters } from "vuex";
import useRequest from "@/utils/useRequest";
import { VueSignaturePad } from "vue-signature-pad";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import SuccessDialog from "@components/dialogs/SuccessDialog";
import viewSupport from "@/mixins/view-support";
import dayjs from "dayjs";
import PdfPreviewProgressBar from "../../PdfPreviewProgressBar.vue";

export default {
  name: "Signature",
  components: {
    VerifiedComplaintBox,
    EditableField,
    PdfViewer,
    SuccessDialog,
    VueSignaturePad,
    PdfPreviewProgressBar,
  },
  mixins: [viewSupport],
  props: {
    filingPlan: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pdf: "",
      isLoading: false,
      signatureText: "",
      successDialog: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
      property: "property/currentProperty",
      isAuthenticated: "isAuthenticated",
      currentClientId: "eviction/currentClientId",
    }),
    isEfile() {
      return this.filingPlan === "E-FILE";
    },
  },
  async activated() {
    window.scrollTo(0, 0);
    this.pdf = null;
    this.isLoading = true;

    if (this.isInStandaloneForms) {
      this.renderTemplate();
    } else {
      //* debounce after two seconds
      setTimeout(() => {
        if (this.$route.name === "vc-signature") this.renderTemplate();
        // console.error("don't forget...");
      }, 2000);
    }

    this.signatureText =
      this.case.signatureText || getAttorneySignatureText(this.case);
    await this.drawSavedSignature();
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    b64toBlob(b64Data, contentType='', sliceSize=512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    async drawSavedSignature() {
      const signatureImageFileName = this.isInStandaloneForms ? this.case.signatureImageClrs : this.case.signatureImage;
      const { data: signatureImage } = await useRequest({
        path: `signatureImage/${this.case.id}/${signatureImageFileName}`,
      });
      const contentType = 'image/png';
      const b64Data = signatureImage.replace('data:image/png;base64,','');

      const blob = this.b64toBlob(b64Data, contentType);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.$refs.signaturePad.fromDataURL(reader.result);
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
    },
    async saveData(isCompletingCase = false) {
      const caseData = {
        signatureText: "",
        isClrsInStandAloneForm:
          this.case.isClrsInStandAloneForm || this.isInStandaloneForms,
      };

      if (this.signatureText !== getAttorneySignatureText(this.case)) {
        caseData.signatureText = this.signatureText;
      }

      if (this.isEfile || this.isInStandaloneForms) {
        //* Save new written signature
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (!isEmpty) {
          if (this.isInStandaloneForms) caseData.newSignatureBase64Clrs = data;
          else if (this.isEfile) caseData.newSignatureBase64 = data;
        }
      }

      if (!this.isClrsInStandAloneForm) {
        caseData.vcDateDrafted = dayjs().format("YYYY-MM-DD");
      }

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: isCompletingCase ? "verifiedComplaintComplete" : "vc-sign",
      });
    },
    next() {
      this.successDialog = true;
    },
    goBack() {
      let nameToPush = "vc-filing";
      if (this.isInStandaloneForms) nameToPush = "saf-lease-registration";

      this.$router.push({ name: nameToPush });
    },
    openPDF() {
      if (!this.pdf) return;
      const data64 = this.pdf.replace("data:application/pdf;base64,", "");
      if (data64 === "undefined") return;
      openPDFInNewTab("FileEviction | Preview", data64);
    },
    async completeCase(authType) {
      const orderInfo = {
        caseId: this.case.id,
        clientId: this.currentClientId,
        payment_status: "Pending",
        packageId: 1,
        flowType: !this.isInStandaloneForms ? "01" : "02",
      };
      if (!this.$auth.isAuthenticated) {
        sessionStorage.setItem("guestOrder", JSON.stringify(orderInfo));
        this.$auth.loginWithRedirect(auth0OptionsHelper(authType));
        return;
      }
      await this.$store.dispatch("eviction/createOrder", {
        payload: { orderInfo },
      });
      this.saveData(true);

      this.$router.push({ name: "case-view-management" });
    },
    async renderTemplate() {
      console.log("renderTemplate is executed, Signature.pdf:287");
      try {
        // if template data is the not same as the last time, we will create a new pdf
        const guestData = !this.$auth.isAuthenticated && {
          property: JSON.parse(sessionStorage.getItem("property")),
          case: JSON.parse(sessionStorage.getItem("case")),
        };

        let templateName = "verified_complaint_preview";
        if (this.isInStandaloneForms) {
          templateName = "clrs_form_preview";
        }
        const { data } = await useRequest({
          path: this.$auth.isAuthenticated
            ? "create-case-pleading-pdf"
            : "create-case-pleading-pdf-guest",
          method: "post",
          data: {
            caseId: this.case.id,
            clientId: this.currentClientId,
            guestData,
            templateName,
            isCLRSOnly: this.isInStandaloneForms,
            filingPlan: this.filingPlan,
          },
          throwError: true,
        });
        const linkSource = `data:application/pdf;base64,${data.fileData}`;
        this.pdf = linkSource;
      } catch (error) {
        console.log("renderTemplate error:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.signature-pad {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px solid #8ba4b4;
  border-radius: 20px;
}
.magnify-icon {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 25px;
  cursor: pointer;
}
</style>
