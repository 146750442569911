<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :mini-variant="mini"
    permanent
    color="secondary"
    class="elevation-6 rounded-b-0"
    style="border-radius: 20px"
  >
    <v-container
      class="d-flex flex-column navlinks"
      style="height: 80%; padding: 0px; overflow: hidden"
    >
      <v-list class="navlinks-list" dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.upcoming ? undefined : { path: item.path }"
          link
          class="py-1"
          :style="item.upcoming ? 'cursor: not-allowed;' : ''"
          @click="navigate(item)"
        >
          <v-list-item-icon>
            <v-icon style="margin: auto" :color="item.upcoming ? '#777' : '#FFF'" size="xx-large">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-container>
    <v-icon
      color="#FFFFFF"
      size="xx-large"
      style="width: 100%; position: absolute; bottom: 20px"
      >icofont-support</v-icon
    >

    <v-dialog v-model="upcomingFeatureDialogOpen" :max-width="500">
      <UpcomingFeatureDialog
        @close:dialog="upcomingFeatureDialogOpen = false"
      />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import UpcomingFeatureDialog from "@/components/dialogs/UpcomingFeatureDialog";

export default {
  name: "Navigation",
  components: {
    UpcomingFeatureDialog,
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: "Home", icon: "icofont-home", path: "/", upcoming: false },
        {
          title: "Tasks",
          icon: "icofont-tasks",
          path: "/tasks",
          upcoming: false,
        },
        {
          title: "Messages",
          icon: "icofont-email",
          path: "/messages",
          upcoming: true,
        },
        {
          title: "Calendar",
          icon: "icofont-calendar",
          path: "/calendar",
          upcoming: false,
        },
        {
          title: "Clients",
          icon: "icofont-ui-user-group",
          path: "/clients",
          upcoming: false,
        },
        {
          title: "Billing",
          icon: "icofont-bill",
          path: "/invoices",
          upcoming: false,
        },
      ],
      mini: true,
      upcomingFeatureDialogOpen: false,
    };
  },
  methods: {
    navigate(item) {
      if (item.upcoming) {
        this.upcomingFeatureDialogOpen = true;
      }
    },
  },
};
</script>

<style scoped>
.navlinks-list {
  margin: 0;
  position: absolute !important;
  top: 45%;
  left: 50%;
  transform: translate(-60%, -50%);
  width: 85%;
}
.v-list-item {
  border-radius: 0 15px 15px 0;
  margin-bottom: 15px;
}
.v-list-item .v-list-item__icon {
  height: 32px !important;
  margin-left: 25% !important;
}
.v-list .v-list-item--active {
  background-color: #6be2da !important;
}
.v-badge >>> .v-badge__badge {
  width: 30px;
  padding-top: 3px !important;
}
</style>
