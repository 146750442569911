<template>
  <div class="mx-auto">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$router.push({ name: 'clients' })"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form
      ref="form"
      v-model="valid"
      style="width: 80%"
      class="pt-10 pb-4 mx-auto text-left"
    >
      <div class="custom-title pt-8 pb-4">Client Billing</div>

      <p class="mb-8">
        Please enter your fees for your services for this client. The selection below will populate the choices.
      </p>

      <div class="text-left">
        <SelectField
          title="How will you charge this client?"
          :field.sync="clientBillingInfo.chargeType"
          :items="chargeTypes"
          rules-type="required"
        />
        <div v-if="clientBillingInfo.chargeType">
          <p class="secondary--text font-weight-bold mb-4">
            {{ clientBillingInfo.chargeType }}
          </p>
          <v-row v-if="clientBillingInfo.chargeType === 'Fee Per Service'">
            <v-col>
              <p class="secondary--text font-weight-medium">
                Verified Complaint
              </p>
              <v-text-field
                v-model="clientBillingInfo.vcFee"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                prepend-inner-icon="mdi-currency-usd"
              />
              <v-switch
                v-model="clientBillingInfo.vcFilingFeesIncluded"
                inset
                label="Filing Fees Included"
              />
            </v-col>
            <v-col>
              <p class="secondary--text font-weight-medium">
                Court Appearance
              </p>
              <v-text-field
                v-model="clientBillingInfo.courtAppearanceFee"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                prepend-inner-icon="mdi-currency-usd"
              />
            </v-col>
            <v-col>
              <p class="secondary--text font-weight-medium">
                Warrant Of Removal
              </p>
              <v-text-field
                v-model="clientBillingInfo.worFee"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                prepend-inner-icon="mdi-currency-usd"
              />
              <v-switch
                v-model="clientBillingInfo.worFilingFeesIncluded"
                inset
                label="Filing Fees Included"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-row>
                <v-col>
                  <p class="secondary--text font-weight-medium">
                    Verified Complaint
                  </p>
                </v-col>
                <v-col>
                  <p class="secondary--text font-weight-medium">
                    Court Appearance
                  </p>
                </v-col>
                <v-col>
                  <p class="secondary--text font-weight-medium">
                    Warrant Of Removal
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="clientBillingInfo.vcFee"
                    rounded
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    prepend-inner-icon="mdi-currency-usd"
                    class="mt-4"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="clientBillingInfo.vcFilingFeesIncluded"
                    inset
                    label="Filing Fees Included"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="mt-8">
            <SelectField
              title="When will the payment for services be due?"
              :field.sync="clientBillingInfo.paymentDueType"
              :items="paymentDueDateItems"
              rules-type="required"
            />
            <v-row v-if="clientBillingInfo.paymentDueType === 'Custom'" class="pl-4 pr-10">
              <p class="secondary--text font-weight-medium mr-4">
                Enter days
              </p>
              <v-text-field
                v-model="clientBillingInfo.paymentDueDays"
                type="number"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
              />
            </v-row>
          </div>
        </div>
      </div>
    </v-form>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Contact",
  data() {
    return {
      valid: true,
      clientBillingInfo: {
        chargeType: null,
        vcFilingFeesIncluded: false,
        worFilingFeesIncluded: false,
        // feesLevel: null,
        vcFee: null,
        courtAppearanceFee: null,
        worFee: null,
        paymentDueType: "",
        paymentDueDays: null,
      },
      chargeTypes: ["Fee Per Service", "Fee For Package"],
      // feesLevelList: ["Fees Level 1", "Fees Level 2", "Fees Level 3", "Custom Fees"],
      paymentDueDateItems: ["Upon receipt", "Custom"],
    };
  },
  computed: {
    ...mapGetters({
      editingClient: "client/editingClient",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.clientBillingInfo.chargeType = this.editingClient.chargeType ?? "Fee Per Service";
    this.clientBillingInfo.vcFilingFeesIncluded = this.editingClient.vcFilingFeesIncluded;
    this.clientBillingInfo.worFilingFeesIncluded = this.editingClient.worFilingFeesIncluded;
    this.clientBillingInfo.vcFee = this.editingClient.vcFee;
    this.clientBillingInfo.courtAppearanceFee = this.editingClient.courtAppearanceFee;
    this.clientBillingInfo.worFee = this.editingClient.worFee;
    this.clientBillingInfo.paymentDueType = this.editingClient.paymentDueType;
    this.clientBillingInfo.paymentDueDays = this.editingClient.paymentDueDays;
  },
  methods: {
    async saveData() {
      await this.$store.dispatch("client/saveClient", { ...this.editingClient, infoType: "contact" });
      await this.$store.dispatch("client/saveClient", { ...this.clientBillingInfo, id: this.editingClient.id, infoType: "billing" });
      await this.$store.dispatch("eviction/loadCases", {}, { root: true });
    },
    async next() {
      await this.saveData();
      this.$router.push({ name: "clients" });
    },
    back() {
      this.$router.push({ name: "single-client-contact" });
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
.v-input--switch >>> .v-label {
  color: #003A60;
}
.col {
  border: 1px solid lightgrey;
}
</style>
