<template>
  <div class="mx-auto">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="isInSingleForm ? back() : $emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-form
      ref="form"
      style="width: 80%"
      class="pt-10 pb-4 mx-auto text-left"
    >
      <div class="custom-title pt-8 pb-4">Client Contact Information</div>

      <p class="mb-12">
        We need a bit more information about your client and your point contact
        with the client. This information will appear in relevant sections of
        the court pleadings.
      </p>

      <div class="text-left">
        <TextField
          title="First Name"
          :field.sync="contactInfo.firstName"
          rules-type="custom"
          :custom-rules="isNameDuplicated ? ['Item is duplicated'] : []"
        />
        <TextField
          title="Last Name"
          :field.sync="contactInfo.lastName"
          rules-type="custom"
          :custom-rules="isNameDuplicated ? ['Item is duplicated'] : []"
        />
        <TextField
          title="Company Name"
          :field.sync="contactInfo.company"
          rules-type="none"
        />
        <TextField
          title="Street Address"
          :field.sync="contactInfo.streetAddress"
        />
        <TextField
          title="Apt/Unit"
          :field.sync="contactInfo.unitNo"
          rules-type="none"
        />
        <TextField title="City" :field.sync="contactInfo.city" />
        <SelectField
          title="State"
          :field.sync="contactInfo.state"
          :items="states"
          rules-type="required"
        />
        <TextField
          title="Zip Code"
          :field.sync="contactInfo.zipcode"
          rules-type="required"
        />
        <PhoneField
          title="Mobile Phone"
          :field.sync="contactInfo.cell"
          icon="$cellphone"
        />
        <div class="phone-with-ext">
          <PhoneField
            title="Home/Office Phone"
            :field.sync="contactInfo.phone"
            icon="$phone"
            rules-type="none"
          />
          <TextField
            title="Ext"
            :field.sync="contactInfo.ext"
            rules-type="none"
          />
        </div>
        <TextField
          title="Email Address"
          :field.sync="contactInfo.email"
          rules-type="email-required"
        />
      </div>
    </v-form>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        {{ isInSingleForm ? "cancel" : "go back" }}
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { states } from "@/resources/index.js";
import useRequest from "@/utils/useRequest";

export default {
  name: "Contact",
  data() {
    return {
      contactInfo: {
        id: null,
        firstName: "",
        lastName: "",
        company: "",
        streetAddress: "",
        unitNo: "",
        city: "",
        state: "New Jersey",
        zipcode: "",
        phone: "",
        phoneType: "office",
        ext: "",
        cell: "",
        email: "",
      },
      states,
      isNameDuplicated: false,
    };
  },
  computed: {
    ...mapGetters({
      currentProperty: "property/currentProperty",
      caseSteps: "eviction/caseSteps",
      currentClient: "eviction/currentClient",
      currentClientId: "eviction/currentClientId",
      isNewClient: "eviction/isNewClient",
      editingClient: "client/editingClient",
      firm: "firm",
    }),
    isInSingleForm() {
      return this.$route.name.startsWith("single-");
    },
    valid() {
      return this.contactInfo.firstName && this.contactInfo.lastName && this.contactInfo.email;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    if (this.isInSingleForm) {
      this.contactInfo = this.editingClient;
    } else {
      const { contactInfo } = this.currentProperty;
      if (contactInfo) {
        this.contactInfo = contactInfo;
      }
    }
  },
  methods: {
    async saveData() {
      if (this.isInSingleForm) {
        // from clients page
        const duplicated = await this.$store.dispatch("client/checkClientExist", { ...this.contactInfo });
        if (duplicated)
          this.isNameDuplicated = true;
        else {
          this.isNameDuplicated = false;
          this.$store.commit("client/set_editing_client", { ...this.editingClient, ...this.contactInfo });
        }
      } else {
        // from eviction flow
        try {
          const { data: res } = await useRequest({
            method: "post",
            path: "attorney/clients",
            data: {
              clientInfo: {
                ...this.contactInfo,
                id: this.contactInfo.clientId,
                infoType: "contact",
              },
              firmId: this.firm.id,
            },
            throwError: true,
          });
          if (res.success) {
            const updatedClient = {
              ...this.currentClient,
              ...this.contactInfo,
              id: this.contactInfo.clientId,
            };
            this.$store.commit("eviction/set_current_client", updatedClient);
            if (this.isNewClient)
              this.$store.commit("eviction/set_current_client_id", res.addedId);

            const propertyData = {
              contactInfoId: res.newContactInfoId,
              status: "contact",
            };
            const caseData = {
              clientInfo: this.contactInfo,
            };
            await this.$store.dispatch("property/saveProperty", {
              propertyData,
              caseData,
            });
            this.isNameDuplicated = false;
          } else {
            this.isNameDuplicated = true;
          }          
        } catch (e) {
          console.log("saveData() error:", e);
        }
      }
    },
    async next() {
      await this.saveData();
      if (this.isNameDuplicated) return;
      if (this.isInSingleForm) {
        this.$router.push({ name: "single-client-billing" });
      } else {
        if (this.isNewClient) {
          const steps = this.caseSteps;
          steps.verifiedComplaint.showChildren = true;
          steps.verifiedComplaint.children.property.showChildren = true;
          steps.verifiedComplaint.children.property.children.contact.status =
            "completed";
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "vc-property-address" });
        } else this.$router.push({ name: "vc-before-property" });
      }
    },
    back() {
      if (this.isInSingleForm) {
        this.$router.push({ name: "clients" });
      } else {
        this.$router.push({ name: "vc-before-client" });
      }
    },
  },
};
</script>

<style>
.phone-with-ext {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}
</style>
