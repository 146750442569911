<template>
  <VerifiedComplaintBox
    :continue-btn-disabled="!valid"
    :vc-cancel-btn-props="{
      btnText: 'Go Back',
    }"
    :vc-continue-btn-props="{
      btnText: 'Update',
    }"
    @vcBoxContinue="handleContinue"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
    @vcBoxBack="goBack"
  >
    <div class="custom-title pt-8 pb-4">Confirm Dates</div>
    <div class="pb-4">
      We need to make sure the following dates will be accurately reflected in
      the Request for Warrant of Removal.
    </div>

    <v-form ref="form" v-model="valid" class="pt-10 pb-4 text-left">
      <div class="text-left">
        <div class="font-weight-medium secondary--text pb-2">Docket Number</div>
        <v-text-field
          v-model="docketNo"
          rounded
          solo
          flat
          dense
          background-color="#F0F5F6"
          :rules="[(v) => !!v || 'Item is required']"
        />

        <div class="font-weight-medium secondary--text pb-2">Trial Date</div>
        <div class="dateAndTime">
          <v-menu
            v-model="trialDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="trialDate"
                append-icon="$calendar"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="trialDateMenu = true"
              />
            </template>
            <v-date-picker
              v-model="selectedTrialDate"
              no-title
              :show-current="false"
              color="primary"
              class="custom-date"
              @input="closedTrialDateMenu"
              :rules="[(v) => !!v || 'Item is required']"
            />
          </v-menu>
        </div>

        <div class="font-weight-medium secondary--text pb-2">
          Date Filed Verified Complaint
        </div>
        <div class="dateAndTime">
          <v-menu
            v-model="vcDateFiledMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vcDateFiled"
                append-icon="$calendar"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="vcDateFiledMenuOpen = true"
              />
            </template>
            <v-date-picker
              v-model="selectedVcDateFiled"
              no-title
              :show-current="false"
              color="primary"
              class="custom-date"
              @input="closedVcDateFiledMenu"
              :rules="[(v) => !!v || 'Item is required']"
            />
          </v-menu>
        </div>

        <div v-if="currentCase.trialAction !== defaultJudgment && currentCase.trialAction !== judgmentForPossession">
          <div class="font-weight-medium secondary--text pb-2">
            Date Filed Consent To Enter Judgment
          </div>
          <div class="dateAndTime">
            <v-menu
              v-model="consentDateFiledMenuOpen"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="consentDateFiled"
                  append-icon="$calendar"
                  color="accent"
                  readonly
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                  v-on="on"
                  @click:append="consentDateFiledMenuOpen = true"
                />
              </template>
              <v-date-picker
                v-model="selectedConsentDateFiled"
                no-title
                :show-current="false"
                color="primary"
                class="custom-date"
                @input="closedConsentDateFiledMenu"
                :rules="[(v) => !!v || 'Item is required']"
              />
            </v-menu>
          </div>
        </div>
      </div>
    </v-form>
  </VerifiedComplaintBox>
</template>

<script>
import { trialActionsString } from "@/utils/worFlowData";
import { mapGetters } from "vuex";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";

export default {
  name: "Trial",
  components: {
    VerifiedComplaintBox,
  },
  data: () => ({
    valid: true,
    docketNo: "",
    trialDate: null,
    selectedTrialDate: null,
    trialDateMenu: false,
    vcDateFiled: null,
    selectedVcDateFiled: null,
    vcDateFiledMenuOpen: false,
    consentDateFiled: null,
    selectedConsentDateFiled: null,
    consentDateFiledMenuOpen: false,
  }),
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    defaultJudgment() {
      return trialActionsString()[0];
    },
    judgmentForPossession() {
      return trialActionsString()[1];
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.docketNo = this.currentCase.docketNo
      ? this.currentCase.docketNo
      : "LT-";
    this.selectedTrialDate = this.currentCase.trialDate ?? null;
    this.trialDate = this.selectedTrialDate
      ? this.$dayjs(this.selectedTrialDate).format("MMMM DD, YYYY")
      : "";
    this.selectedVcDateFiled = this.currentCase.vcDateFiled ?? null;
    this.vcDateFiled = this.selectedVcDateFiled
      ? this.$dayjs(this.selectedVcDateFiled).format("MMMM DD, YYYY")
      : "";
    this.selectedConsentDateFiled = this.currentCase.vcDateConsentFiled ?? null;
    this.consentDateFiled = this.selectedConsentDateFiled
      ? this.$dayjs(this.selectedConsentDateFiled).format("MMMM DD, YYYY")
      : "";
  },
  deactivated() {
    this.save();
  },
  methods: {
    handleContinue() {
      this.$router.push({ name: "wor-trial-signature" });
    },
    goBack() {
      switch (this.currentCase.trialAction) {
        case trialActionsString()[0]: // Request for Warrant of Removal - Default Judgment
        case trialActionsString()[1]: // Request for Warrant of Removal - Judgment for Possession
          this.$router.push({ name: "wor-trial-rent-ledger" });
          break;
        default:
          this.$router.push({ name: "wor-trial-consent-ledger" });
          break;
      }
    },
    async save() {
      const caseData = {
        docketNo: this.docketNo ?? null,
        trialDate: this.selectedTrialDate,
        vcDateFiled: this.selectedVcDateFiled,
        vcDateConsentFiled: this.selectedConsentDateFiled,
      };

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "wor-confirmDates",
        logMessage: "ConfirmDates.vue:162",
      });
    },
    closedTrialDateMenu(data) {
      this.selectedTrialDate = data;
      this.trialDate = this.$dayjs(this.selectedTrialDate).format(
        "MMMM DD, YYYY"
      );
      this.trialDateMenu = false;
    },
    closedVcDateFiledMenu(data) {
      this.selectedVcDateFiled = data;
      this.vcDateFiled = this.$dayjs(this.selectedVcDateFiled).format(
        "MMMM DD, YYYY"
      );
      this.vcDateFiledMenuOpen = false;
    },
    closedConsentDateFiledMenu(data) {
      this.selectedConsentDateFiled = data;
      this.consentDateFiled = this.$dayjs(this.selectedConsentDateFiled).format(
        "MMMM DD, YYYY"
      );
      this.consentDateFiledMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.dateAndTime {
  display: flex;
  gap: 20px;
}
</style>
