<template>
  <div class="py-4 px-12 mx-4">
    <div class="d-flex align-center justify-space-between my-8">
      <div class="d-flex flex-row align-center secondary--text">
        <v-icon color="primary" size="xxx-large" @click="$router.push('/')"
          >mdi-arrow-left-drop-circle</v-icon
        >
        <span class="pl-8 font-weight-medium" style="font-size: 40px">
          MATTER #:
          <span style="text-transform: uppercase">
            {{
              `${currentCase.attorneyInfo.firstName}${currentCase.attorneyInfo.lastName}`.slice(
                0,
                3
              ) +
              "-" +
              `${currentCase.clientInfo.firstName}${currentCase.clientInfo.lastName}`.slice(
                0,
                3
              ) +
              "-" +
              currentCase.id
            }}
          </span>
        </span>
      </div>
      <div class="d-flex flex-row align-center secondary--text">
        <span class="ml-4" style="font-size: 30px">
          Client:
          {{
            `${currentCase.clientInfo.firstName} ${currentCase.clientInfo.lastName}`
          }}
        </span>
      </div>
    </div>

    <SubTopNav :value="0" />

    <v-container fluid class="case-view">
      <v-row class="mb-8">
        <v-col class="mt-3 px-6 flex-grow-1 flex-shrink-0" cols="8">
          <v-row class="mb-12">
            <CaseOverviewCard
              :current-case="currentCase"
              :currentProperty="currentProperty"
              :courtAddress="courtAddress"
            />
          </v-row>
          <!-- <v-row>
            <MessagesCard :documents="currentCase.documents" />
          </v-row> -->
          <v-row class="pt-8">
            <TasksCard :documents="currentCase.documents" />
          </v-row>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1" cols="4">
          <CurrentStatus />
          <NextStep class="mt-8" />
          <CaseProgressCard class="mt-8" :currentCase="currentCase" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CurrentStatus from "@/components/CurrentStatus";
import NextStep from "@/components/NextStep";
import CaseOverviewCard from "@/components/CaseOverviewCard";
import MessagesCard from "@/components/MessagesCard.vue";
import TasksCard from "@/components/TasksCard.vue";
import CaseProgressCard from "@/components/CaseProgressCard";
import SubTopNav from "@/components/SubTopNav.vue";
import { mapGetters } from "vuex";

export default {
  name: "CaseView",
  components: {
    CurrentStatus,
    NextStep,
    CaseOverviewCard,
    SubTopNav,
    CaseProgressCard,
    MessagesCard,
    TasksCard,
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      currentProperty: "property/currentProperty",
      courtAddress: "eviction/courtInfo",
    }),
  },
};
</script>

<style>
.rounded-card.v-card.v-sheet.theme--light {
  border-radius: 20px;
}
.active-bottom-btn {
  color: white !important;
  background-color: #4dccc4 !important;
}
</style>
