<template>
  <v-card
    class="d-flex flex-column secondary--text justify-center"
    min-height="350"
  >
    <div
      class="pa-10 d-flex flex-column align-center"
      style="min-height: 300px"
    >
      <i :class="`icofont-exclamation-circle icofont-6x ${color}--text`" />
      <div class="text-left">
        {{ text }}
      </div>
    </div>
    <div class="d-flex">
      <v-btn
        tile
        class="white--text text-center pa-8 font-weight-medium capital--btn accent"
        style="width: 50%"
        @click="$emit('close')"
      >
        <span style="font-weight: 600 !important">No</span>
      </v-btn>
      <v-btn
        tile
        class="white--text text-center pa-8 font-weight-medium capital--btn primary"
        style="width: 50%"
        @click="$emit('confirm')"
      >
        <span style="font-weight: 600 !important">Yes</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
