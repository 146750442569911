<template>
  <div>
    <v-card
      class="rounded-card pt-2"
      style="box-shadow: 15px 15px 40px #00000029; min-width: 300px"
    >
      <v-card-title
        class="font-weight-bold secondary--text ml-8"
        style="font-size: 1.25rem"
        >Case Progress</v-card-title
      >
      <v-list class="case-progress">
        <div v-for="(item, index) in steps" :key="index">
          <v-list-item
            class="py-4 px-12 d-flex justify-space-between"
            :class="[
              item.completed
                ? 'case-progress-complete'
                : 'case-progress-incomplete',
            ]"
            @click="goToStep(index)"
          >
            <div :class="item.completed ? 'white--text' : 'secondary--text'">
              <v-icon
                :color="item.completed ? 'white' : 'primary'"
                class="pr-2"
              >
                mdi-{{
                  item.completed
                    ? "check-circle"
                    : "checkbox-blank-circle-outline"
                }}
              </v-icon>
              {{ item.label }}
              <span
                v-if="
                  item.label === 'Filed Verified Complaint' &&
                  currentCase.vcDateFiled
                "
              >
                ({{ $dayjs(currentCase.vcDateFiled).format("MMMM DD, YYYY") }})
              </span>
            </div>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trialActionsString } from "@/utils/worFlowData";

export default {
  name: "CaseProgressCard",
  props: {
    currentCase: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 0,
      vcCompleted: false,
      clrsCompleted: false,
      // courtInfoCompleted: false,
      // courtOutcomeCompleted: false,
      trialInfoCompleted: false,
      trialOutcomeCompleted: false,
      requestWORCompleted: false,
      steps: [],
    };
  },
  computed: {
    ...mapGetters({
      caseSteps: "eviction/caseSteps",
    }),
  },
  created() {
    if (!this.currentCase?.actions) return;
    const actionId = this.currentCase.actions[0]?.actionId;
    switch (actionId) {
      case 191: // Complete CLORS
        this.vcCompleted = true;
        if (this.currentCase.clrsSubmission || this.currentCase.clrsExemption)
          this.clrsCompleted = true;
        break;
      // case 21: // Enter Court Information
      //   this.vcCompleted = true;
      //   this.clrsCompleted = true;
      //   break;
      // case 31: // Court Outcome
      //   this.vcCompleted = true;
      //   this.clrsCompleted = true;
      //   this.courtInfoCompleted = true;
      //   if (this.currentCase.trialDate && this.currentCase.trialTime)
      //     this.trialInfoCompleted = true;
      //   break;
      case 41: // Enter Trial Information
        this.vcCompleted = true;
        this.clrsCompleted = true;
        // this.courtInfoCompleted = true;
        break;
      case 51: // Post Trial Actions
        this.vcCompleted = true;
        this.clrsCompleted = true;
        // this.courtInfoCompleted = true;
        // this.courtOutcomeCompleted = true;
        if (this.currentCase.trialDate && this.currentCase.trialTime)
          this.trialInfoCompleted = true;
        break;
      case 61: // Request for Warrant Of Removal
        this.vcCompleted = true;
        this.clrsCompleted = true;
        // this.courtInfoCompleted = true;
        // this.courtOutcomeCompleted = true;
        if (this.currentCase.trialDate && this.currentCase.trialTime)
          this.trialInfoCompleted = true;
        this.trialOutcomeCompleted = true;
        break;
      default:
        break;
    }

    if (this.trialOutcomeCompleted && trialActionsString().slice(0, 5).includes(this.currentCase.trialAction)) {
      this.requestWORCompleted = true;
    }

    this.steps = [
      {
        label: "Verified Complaint",
        completed: this.vcCompleted,
        onclick: () => {
          if (this.vcCompleted) this.$router.push({ name: "vc-signature" });
          else this.$store.dispatch("eviction/editCase");
        },
      },
      {
        label: "Certification of Lease and Registration Statement",
        completed: this.clrsCompleted,
        onclick: () => {
          this.$router.push({ name: "saf-lease-registration" });
        },
      },
      {
        label: "Filed Verified Complaint",
        completed: this.currentCase.vcDateFiled !== null,
        onclick: () => {
          this.$router.push({ name: "saf-filed-verified-complaint" });
        },
      },
      // {
      //   label: "Schedule Case Management Conference",
      //   completed: this.courtInfoCompleted,
      //   onclick: () => {
      //     this.$router.push({ name: "saf-court-information" });
      //   },
      // },
      {
        label: "Schedule Trial Date",
        completed: this.trialInfoCompleted,
        onclick: () => {
          this.$router.push({ name: "wor-trial" });
        },
      },
      // {
      //   label: "Update Ledger",
      //   completed: this.courtOutcomeCompleted,
      //   onclick: () => {
      //     this.$store.commit(
      //       "eviction/set_nextStepAfterLedger",
      //       "courtOutcome"
      //     );
      //     this.$router.push({ name: "case-view-ledger" });
      //   },
      // },
      // {
      //   label: "Case Management Outcome",
      //   completed: this.courtOutcomeCompleted,
      //   onclick: () => {
      //     this.$router.push({ name: "wor-court-outcome" });
      //   },
      // },
      {
        label: "Update Ledger",
        completed: this.trialOutcomeCompleted,
        onclick: () => {
          this.$store.commit(
            "eviction/set_nextStepAfterLedger",
            "trialOutcome"
          );
          this.$router.push({ name: "case-view-ledger" });
        },
      },
      {
        label: "Post Trial Actions",
        completed: this.trialOutcomeCompleted,
        onclick: () => {
          const steps = this.caseSteps;
          steps.updateLedgerBeforeTrialOutcome.status = "completed";
          steps.trialOutcome.showChildren = true;
          if (this.trialOutcomeCompleted) {
            switch (this.currentCase.trialAction) {
              case trialActionsString()[0]: // Request for Warrant of Removal - Default Judgment
              case trialActionsString()[1]: // Request for Warrant of Removal - Judgment for Possession
                steps.trialOutcome.children = {
                  consentLedger: {
                    label: "Rent Ledger",
                    status: "completed",
                    routeName: "wor-trial-rent-ledger",
                  },
                  confirmDates: {
                    label: "Confirm Dates",
                    status: "completed",
                    routeName: "wor-trial-confirm-dates",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
                steps.trialOutcome.children = {
                  paymentPlan: {
                    label: "Payment Plan",
                    status: "completed",
                    routeName: "wor-trial-payment-plan",
                  },
                  rentConsentTerm: {
                    label: "Rent During the Consent Term",
                    status: "completed",
                    routeName: "wor-trial-rent-consent-term",
                  },
                  confirmBreach: {
                    label: "Confirmation of Breach",
                    status: "completed",
                    routeName: "wor-trial-confirm-breach",
                  },
                  consentLedger: {
                    label: "Rent Ledger",
                    status: "completed",
                    routeName: "wor-trial-consent-ledger",
                  },
                  confirmDates: {
                    label: "Confirm Dates",
                    status: "completed",
                    routeName: "wor-trial-confirm-dates",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
                steps.trialOutcome.children = {
                  paymentPlan: {
                    label: "Payment Plan",
                    status: "completed",
                    routeName: "wor-trial-payment-plan",
                  },
                  confirmBreach: {
                    label: "Confirmation of Breach",
                    status: "completed",
                    routeName: "wor-trial-confirm-breach",
                  },
                  consentLedger: {
                    label: "Rent Ledger",
                    status: "completed",
                    routeName: "wor-trial-consent-ledger",
                  },
                  confirmDates: {
                    label: "Confirm Dates",
                    status: "completed",
                    routeName: "wor-trial-confirm-dates",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
                steps.trialOutcome.children = {
                  rentConsentTerm: {
                    label: "Rent During the Consent Term",
                    status: "completed",
                    routeName: "wor-trial-rent-consent-term",
                  },
                  confirmBreach: {
                    label: "Confirmation of Breach",
                    status: "completed",
                    routeName: "wor-trial-confirm-breach",
                  },
                  consentLedger: {
                    label: "Rent Ledger",
                    status: "completed",
                    routeName: "wor-trial-consent-ledger",
                  },
                  confirmDates: {
                    label: "Confirm Dates",
                    status: "completed",
                    routeName: "wor-trial-confirm-dates",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[5]: // Executed Consent to Enter Judgment - Tenant Stays
                steps.trialOutcome.children = {
                  filedConsentJudgment: {
                    label: "Filed Consent To Enter Judgment",
                    status: "completed",
                    routeName: "wor-trial-filed-consent-judgment",
                  },
                  paymentPlan: {
                    label: "Payment Plan",
                    status: "completed",
                    routeName: "wor-trial-payment-plan",
                  },
                  rentConsentTerm: {
                    label: "Rent During the Consent Term",
                    status: "completed",
                    routeName: "wor-trial-rent-consent-term",
                  },
                  additionalConditions: {
                    label: "Additional Conditions",
                    status: "completed",
                    routeName: "wor-trial-additional-condition",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
                steps.trialOutcome.children = {
                  filedConsentJudgment: {
                    label: "Filed Consent To Enter Judgment",
                    status: "completed",
                    routeName: "wor-trial-filed-consent-judgment",
                  },
                  moveOutDate: {
                    label: "Move Out Date",
                    status: "completed",
                    routeName: "wor-trial-move-out-date",
                  },
                  paymentPlan: {
                    label: "Payment Plan",
                    status: "completed",
                    routeName: "wor-trial-payment-plan",
                  },
                  additionalConditions: {
                    label: "Additional Conditions",
                    status: "completed",
                    routeName: "wor-trial-additional-condition",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              case trialActionsString()[7]: // Executed Consent to Enter Judgment - Tenant Vacates without Payment Plan
                steps.trialOutcome.children = {
                  filedConsentJudgment: {
                    label: "Filed Consent To Enter Judgment",
                    status: "completed",
                    routeName: "wor-trial-filed-consent-judgment",
                  },
                  moveOutDate: {
                    label: "Move Out Date",
                    status: "completed",
                    routeName: "wor-trial-move-out-date",
                  },
                  signature: {
                    label: "Let's Finalize",
                    status: "completed",
                    routeName: "wor-trial-signature",
                  },
                };
                break;
              default:
                break;
            }
            this.$store.commit("eviction/update_steps", steps);
            this.$router.push({ name: "wor-trial-signature" });
          } else {
            this.$store.commit("eviction/update_steps", steps);
            this.$router.push({ name: "wor-trial-action" });
          }
        },
      },
      // {
      //   label: "Request Warrant Of Removal",
      //   completed: this.requestWORCompleted,
      //   onclick: () => {
      //     if (this.requestWORCompleted) {
      //       const caseSteps = this.caseSteps;
      //       caseSteps.requestWOR = {
      //         label: "Request Warrant Of Removal",
      //         status: "completed",
      //         routeName: "warrant-of-removal",
      //       };
      //       this.$store.commit("eviction/update_steps", caseSteps);
      //       this.$router.push({ name: "wor-trial-signature" });
      //     } else {
      //       this.$router.push({ name: "wor-trial-action" });
      //     }
      //   },
      // },
      {
        label: "Filed Warrant Of Removal",
        completed: this.requestWORCompleted,
        onclick: () => {
          this.$router.push({ name: "saf-filed-wor" });
        },
      },
      // {
      //   label: "Request Warrant Of Removal",
      //   completed: this.trialOutcomeCompleted,
      //   onclick: () => {
      //     this.$router.push({ name: "wor-trial-action" });
      //   },
      // },
    ];
    // if (
    //   courtOutcomesString().slice(2, 5).includes(this.currentCase.courtOutcome)
    // ) {
    //   this.steps.splice(7, 0, {
    //     label: "Terms of Consent To Enter Judgment",
    //     completed:
    //       this.currentCase.vcDateConsentFiled !== null &&
    //       this.currentCase.courtOutcome !== null,
    //     onclick: () => {
    //       this.$router.push({ name: "saf-terms-consent-judgment" });
    //     },
    //   });
    // }
    
    if (!this.currentCase.clrsDraft) {
      [this.steps[1], this.steps[2]] = [this.steps[2], this.steps[1]];
    }
  },
  methods: {
    goToStep(index) {
      // if (this.steps[index].label === "Case Management Outcome") {
      //   if (
      //     !this.steps[index - 1].completed &&
      //     !this.steps[index - 2].completed &&
      //     !this.steps[index - 3].completed
      //   )
      //     return;
      // } else 
      if (this.steps[index].label === "Post Trial Actions") {
        if (
          !this.steps[index - 1].completed &&
          !this.steps[index - 2].completed
        )
          return;
      } else if (
        this.steps[index].label === "Certification of Lease and Registration Statement"
      ) {
        if (this.steps[index - 1].label.includes("Filed Verified Complaint")) {
          this.steps[index].onclick();
          return;
        }
      } else if (
        index > 0 &&
        this.steps[index - 1].label !== "Schedule Trial Date"
      ) {
        if (index > 0 && !this.steps[index - 1].completed) return;
      }
      this.steps[index].onclick();
    },
  },
};
</script>

<style scoped>
.v-list {
  padding: 0px !important;
}
.case-progress {
  border-radius: 20px;
  min-width: 260px;
}

.case-progress > div:last-child > div {
  border-radius: 0 0 20px 20px;
}

.case-progress-incomplete {
  background-color: white;
  /* pointer-events: none; */
  opacity: 1;
}

.case-progress-complete {
  background-color: #4dccc4;
  /* border-radius: 20px; */
}

.item-selected {
  background-color: rgba(77, 204, 196, 0.7);
}
.property-item-selected {
  background-color: #eff1f2;
}

.case-progress-subchild {
  background-color: rgb(248, 250, 251);
  border-radius: 0% !important;
}
</style>
